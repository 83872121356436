$backGroundColor: #fafbff;
$buttonColor: #03a9f4;
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #868b90;
$heading-color: #19212c;
$copyWright-color: #626c81;
$dashBoard-btn: rgba(22, 175, 244, 0.2);
$dividerColor: #e6e8f0;
$menuItemColor: #626c81;
$selectedItemColor: #16aff4;
$inputFocus: #81c54b;
$disableColor: #868b90;
$paperbackgroundGreen: #e9ffd8;
$paperbackgroundBlue: #dff5ff;
$checkBoxItem: #4f4f4f;
$descriptionLabel: #323f4b;
$tableHeaderColor: #f9f9f9;
$headingColor: #202020;
$lineBreakColor: #e7e7e7;
$descriptionColor: #5a607f;
$dangerColor: #df4f63;
$greenBackground: #90c35d;
$cellBorderColor: #d9e1e7;
$btnGroupBackGroundColor: #809fb8;
$formHeadingLabelColor: #3b414f;
$shadowColor: #8f95b226;
$link_pale_blue: #7e84a3;
$metal_blue: #626c81;
$darkHeader: #081735;
