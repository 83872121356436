@import "../../common.scss";

.dropDownOption {
  min-height: 42px !important;
  color: $metal_blue !important;
}

.dropDownOption:hover {
  color: #16aff4 !important;
  svg {
    color: #16aff4 !important;
  }
}
.ant-dropdown-menu-item-active {
  background: rgba(22, 175, 244, 0.2) !important;
  border-radius: 0px;
}
