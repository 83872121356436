.mb0 {
  margin-bottom: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.textend {
  text-align: end;
}

.flexCenter {
  display: inline-flex;
  align-items: center;
}

.w100 {
  width: 100%;
}

.spaceEnd {
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.spaceBetween {
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .mobile {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .desktop {
    display: none !important;
  }
}
