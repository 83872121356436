@import "../../styles/common.scss";
@import "../../styles/dashboardCommon.scss";

.contactSupport {
  .ant-modal-footer {
    justify-content: flex-end !important;
    gap: 20px;
  }
  .progressList > div {
    padding: 16px;

    span .anticon svg {
      position: relative;
      width: 17px;
      height: 17px;
    }
  }

  .uploadDrag {
    padding: 0;
    gap: 0;
    width: 120px;
    height: 120px;
    svg {
      width: 26px;
      height: 26px;
    }
  }

  .contactSH2 {
    text-align: left;
    font-size: 24px;
    width: 100%;
    margin: 0;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .ant-select-selector {
    border: 1px solid #626c81 !important;
    height: 48px !important;
    background-color: #fff;
  }

  .multipleSelectOptions .ant-select-selector {
    height: auto !important;
    min-height: 48px !important;
  }

  .stdSelect {
    width: 100% !important;
  }

  .label {
    font-size: 14px;
    color: #626c81;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 8px;
  }

  [type="text"] {
    height: 48px;
    border: 1px solid #626c81 !important;
    background-color: #fff;
  }

  textarea {
    border: 1px solid #626c81 !important;
    background-color: #fff !important;
    box-shadow: none !important;
    outline: none;
  }

  .ant-input-textarea-affix-wrapper {
    border: none;
    background: #fff !important;
    box-shadow: none !important;
  }
}
