.concent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 111111111111111;
  backdrop-filter: blur(10px);
}

.concent-popup {
  position: fixed;
  inset: 0;
  z-index: 222222222222222;
  margin: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 800px;
  width: 94%;
  max-height: 500px;
  height: 80vh;
  display: flex;
  flex-direction: column;

  .data {
    flex: 1;
    overflow: auto;
    line-height: 1.5;
    // &::-webkit-scrollbar {
    // 	width: 6px;
    // }
    // &::-webkit-scrollbar-thumb {
    // 	background-color: #555;
    // 	border-radius: 10px;
    // }
  }

  h2 {
    font-weight: 600;
    margin: 0;
    margin-right: auto;
    text-wrap: balance;
  }

  p {
    margin-top: 20px;
    margin-bottom: 0;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 600;
  }

  .head {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;

    img {
      width: 70px;
    }
  }

  .blueSignature {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 20px 0;
    align-items: flex-end;
    font-weight: 700;
    color: #03a9f4;
    a {
      color: #03a9f4;
    }
  }
}
