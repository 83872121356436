@import "./colors.scss";
@import "./helpers";

$MontserratFont: "Montserrat", system-ui, sans-serif;

@mixin normalTextStyle {
  color: $color-black;
  font-size: 100%;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: center;
  font-family: $MontserratFont;
}

@mixin HeadingFontStyle {
  font-family: $MontserratFont;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0em;
  text-align: left;
  color: $color-black;
}

@mixin inputLabelFontStyle {
  font-family: $MontserratFont;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0em;
  text-align: left;
  color: $color-grey;
}

@mixin dashboardTitle {
  font-family: $MontserratFont;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin donutChartHeading {
  font-family: $MontserratFont;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin dropDownFontStyle {
  font-family: $MontserratFont;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

@mixin checkboxItem {
  font-family: $MontserratFont;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: $checkBoxItem;
}

@mixin descriptionLabel {
  font-family: $MontserratFont;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin quickLinksTitle {
  font-family: $MontserratFont;
  color: #5a607f;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin tableHeaderStyle {
  font-family: $MontserratFont;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: $tableHeaderColor;
}

@mixin commonBtn {
  background-color: $buttonColor;
  color: $color-white;

  &:hover,
  &:active,
  &:focus {
    background-color: $buttonColor;
    color: $color-white;
  }
}

@mixin commonBtnHover {
  background-color: $buttonHoverColor;
  color: $color-white;
  &:hover {
    background-color: $buttonHoverColor;
    color: $color-white;
  }
}

@mixin commonGhostRedBtn {
  background-color: $color-white;
  color: $dangerColor;
  border: 1px solid $dangerColor;

  &:hover {
    background-color: $color-white;
    border: 1px solid $dangerColor;
    color: $dangerColor;
  }
}

@mixin errorMessageStyle {
  font-family: $MontserratFont;
  font-size: 28px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0px;
  text-align: center;
  color: $menuItemColor !important;
}

@mixin errorMessageMobileStyle {
  font-family: $MontserratFont;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
}

@mixin calendarEvent {
  font-family: $MontserratFont;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
}

@mixin formTemplateHeading {
  font-family: $MontserratFont;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.ant-form-item-explain-error {
  text-align: left;
}
