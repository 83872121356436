@import "../../common.scss";

.ErrorPage {
  width: 100% !important;
  background-color: $backGroundColor !important;
  min-height: 100vh;
  .pageNotFoundLogo {
    position: relative;
    height: 34px;
    width: 150px;
    margin: 32px;
    // left: 41.6015625px;
    // top: 40px;
    border-radius: 0px;
  }

  .pageNotFoundContent {
    position: relative;
    width: 100%;
  }

  .errorContentImage {
    text-align: center;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .errorContentMessage {
    display: flex;
    @include errorMessageStyle;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      @include errorMessageMobileStyle;
      margin-top: 20px;
    }
  }

  .errorContentButton {
    text-align: center;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }

    svg {
      margin-right: 10px;
    }

    .ant-btn {
      height: 56px;
      background-color: $selectedItemColor;
      color: $color-white;

      &:hover {
        background-color: $selectedItemColor;
        color: $color-white;
      }
    }
  }
}

@media (max-width: 768px) {
  .errorContentMessage {
    font-size: 16px;
  }
}
