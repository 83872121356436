$blue_color: #1e5ba8;
$white_color: #ffffff;
$black_color: #000000;
$nobel_color: #949494;
$silver_color: #9b9b9b;
$charcoal_color: #4a4a4a;
$grey_color: rgba(198, 198, 198, 0.3);
$blue_color: #4578b3;
$pale_blue_color: #afd8eb;
$nobel_color: #979797;
$golden_color: #ba8e53;
$darkpink_color: #fd0a50;
$dark_blue_color: #4f535f;
