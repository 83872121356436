@import "./helpers/utils.scss";

@mixin dashboardCard {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(143, 149, 178, 0.15);
  border-radius: 16px;
  padding: 24px;
  border: none;

  .ant-card-body {
    padding: 0;
  }
}

.ant-checkbox > div:first-child {
  display: none !important;
}

.mainContentLayout {
  overflow-x: hidden;
}

.adminDashBoardLayout {
  padding: 40px 30px;
}

.ant-layout {
  background: #fafbff !important;
}

.dashBoardCommonStyle {
  background: #fafbff !important;

  .ant-layout-header {
    line-height: 1;
  }

  .relologoNavBar {
    text-align: center;
    display: grid;
    justify-self: center;
    align-items: center;
    padding: 24px 0;
  }

  .navigationLogo {
    width: 150px !important;
    margin: auto !important;
  }

  .authReloBtnDiv {
    height: 56px !important;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .authReloBtn {
      height: 100%;
      width: 100%;
      font-weight: 500;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $selectedItemColor !important;
      box-shadow: 1px 6px 18px 2px rgba(22, 175, 244, 0.2);

      & svg {
        margin-right: 5%;
      }

      &:disabled {
        background-color: $selectedItemColor !important;
        opacity: 0.8;
        cursor: not-allowed;
        filter: grayscale(1);
        color: #fff;
      }
    }
  }

  .dashBoardSlider {
    background-color: $color-white !important;
    flex: none !important;
    width: 20% !important;
    max-width: 260px !important;
    height: 100%;
    border-right: solid 1px;
    border-color: $dividerColor;
    padding: 0 18px;
    overflow: auto;

    & ul {
      border-inline-end: 0 !important;
    }

    .ant-menu-item-only-child {
      padding-left: 24px !important;
      background-color: $color-white !important;
    }
  }

  .MenuSingleItem {
    margin: auto;
    height: auto;
    padding-bottom: 20px;

    .ant-menu-title-content {
      font-weight: 600;
    }

    & > li {
      color: $menuItemColor !important;
      padding: 4px 10px !important;
      margin-top: 14px;
      min-height: 48px;

      & div {
        padding: 4px 10px !important;
        height: auto !important;
      }

      .ant-menu-submenu-title,
      .ant-menu-title-content {
        color: $menuItemColor !important;

        &:hover {
          color: #222 !important;
        }
      }

      .ant-menu-item-selected {
        .ant-menu-submenu-title,
        .ant-menu-title-content {
          color: $buttonColor !important;

          &:hover {
            color: $buttonColor !important;
          }
        }
      }
    }

    & .ant-menu-submenu {
      padding: 0 !important;
    }
  }

  .ant-menu-item {
    font-family: "Montserrat" !important;
    display: flex !important;
    align-items: center !important;
  }

  .dashBoardHeader {
    > div {
      height: 64px;
    }

    text-align: center;

    .bellNotification {
      cursor: pointer;

      img {
        width: 24px;
      }
    }
  }

  .dashboardHeaderLeft {
    text-align: left;
    padding-left: 30px;

    .companyName {
      font-size: 18px;
      color: $dark_blue_color;
      font-weight: 600;
      text-overflow: clip;
      overflow: hidden;
      max-width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .dashboardHeaderRight {
    text-align: right;
    padding-right: 30px;

    .dashboardRightInner {
      display: flex;
      justify-content: flex-end;
      gap: 40px;
      align-items: center;
      line-height: 1;
    }

    .dashboardUserIcon {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 50%;
    }

    .dashboardUserBar {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .greenBreadcrum {
    color: #81c54b;

    a {
      color: #81c54b;
    }
  }

  .ant-breadcrumb {
    .ant-breadcrumb-link:hover {
      background: #fff0;
    }

    a {
      &:hover {
        background: none;
      }
    }
  }

  .firstBreadcrumbIcon {
    position: relative;
    top: 2px;
  }

  .ant-breadcrumb {
    ol {
      align-items: center;
    }
  }

  .adminToolBarBtn {
    height: 100%;
    width: 100%;
    padding: 8px 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $selectedItemColor !important;
    gap: 6px;
    font-weight: 500;

    &.iconWhite {
      path {
        fill: #fff;
      }
    }

    & svg {
      height: 24px;
    }

    &.clearAllBtn {
      background: $dangerColor !important;
      border-color: $dangerColor !important;
    }

    &.greenBtn {
      background: $inputFocus !important;
      border-color: $inputFocus !important;
    }

    &.redBtn {
      background: $dangerColor !important;
      border-color: $dangerColor !important;
    }

    &.orangeBtn {
      background: #f19f06 !important;
      border-color: #f19f06 !important;
    }
  }

  .customTypo {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 0;

    svg {
      width: 24px;
    }
  }

  .specialGreen {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    background: rgba(129, 197, 75, 0.12);
    color: #81c54b;
    font-weight: 500;
    border-radius: 6px;
    padding: 8px 10px;
    justify-content: space-between;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      color: #81c54b;
    }
  }

  .textAfter {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .ant-select-selector {
    border-color: $metal_blue !important;
  }

  .ant-input-affix-wrapper {
    border-color: $metal_blue !important;
  }

  ::placeholder {
    color: $metal_blue !important;
  }

  .searchPad {
    padding: 8px;
  }

  .ant-select-item {
    align-items: center;
    padding: 12px 12px !important;
  }

  .ant-select-item:hover {
    color: #16aff4;
    background: rgba(22, 175, 244, 0.2);
  }

  .selectDropDown {
    min-height: 32px !important;
  }

  .upcomingVisaExp {
    border-radius: 16px;
    box-shadow: 0px 8px 16px 0px #8f95b226;
    background-color: $paperbackgroundGreen;

    &.upcomingAssignExp {
      background-color: $paperbackgroundBlue;
    }

    .flexCenter {
      cursor: pointer;
      gap: 10px;
      justify-content: flex-start;
    }

    .viewAll {
      font-size: 18px;
      color: $link_pale_blue;
    }

    .next60 {
      color: $descriptionColor;

      & + svg {
        color: #7e84a3;
      }
    }

    .paperItemLine {
      h3 {
        margin: 0;
        font-size: 26px;
        font-weight: 400;
      }

      .mainValues {
        margin-bottom: 20px;
      }
    }
  }

  .dashboardPolicyCharts {
    margin-top: 40px;
    height: 100%;
  }

  .dashboardServiceGraph {
    margin-top: 40px;
  }

  .dashboardBarGraph {
    min-width: 450px;
    overflow-x: auto;
    @include dashboardCard;

    & .ant-card-body {
      padding: 0px !important;
      height: 100%;
    }

    .servicegraphFilters {
      margin-bottom: 20px;

      .servicegraphTitle {
        font-family: $MontserratFont;
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 22px;
        letter-spacing: 0em;
      }

      .serviceGraphFilterBtns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
      }
    }

    .barChartStyle {
      min-width: 450px;
      overflow-x: auto;
      min-height: 400px;
    }

    .barChartLegends {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 30px;
      flex-wrap: wrap;

      > span {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      background: $dashBoard-btn !important;
      color: $selectedItemColor !important;
    }
  }

  .ant-menu-item {
    &ul {
      background-color: $color-white !important;
    }
  }

  .ant-menu-item-selected {
    border-radius: 12px;
    background-color: $selectedItemColor 20%;
    color: $selectedItemColor !important;
  }

  .ant-menu-submenu {
    margin-top: 16px !important;

    & .dashboardMenuIcon {
      fill: red !important;
    }

    & ul {
      background-color: $color-white !important;
    }
  }

  .settingDropdown {
    height: 64px;
    width: 300px;
    left: 0px;
    top: 8px;
    border-radius: 0px;
  }

  .profileIcon {
    & a {
      color: $metal_blue;
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }

  .mainLayoutContent {
    height: 100%;
    width: 100%;
    background-color: $backGroundColor;
    overflow-y: scroll;
  }

  .neatTable {
    overflow: auto;

    .ant-table-thead > tr > th {
      font-weight: 400;
      background: #fff0;
      border-bottom: 1px solid #f0f0f0;

      &::before {
        display: none;
      }
    }

    .ant-table-tbody > tr > td {
      color: $metal_blue;
      border-bottom: 0;
    }

    .ant-table-tbody > tr:nth-child(odd) > td {
      background: #f9f9f9;
    }

    .ant-table-tbody > tr:nth-child(even) > td {
      background: #fff;
    }
  }

  .ant-pagination-item-active {
    background-color: $buttonColor;
    border-color: $buttonColor;

    a {
      color: #fff !important;
    }
  }

  .showMoreLessBtn {
    display: flex;
    border: none;
    text-align: center;
    justify-content: center;
    margin: auto;
    align-items: center;
    gap: 4px;
    color: $metal_blue;
    box-shadow: none !important;
    line-height: 1;
    outline: none;

    > div {
      display: none !important;
    }

    svg {
      transition: all 0.25s;
      width: 23px;
    }

    &.open svg {
      transform: rotate(180deg);
    }
  }

  .defautlBtnPad {
    padding: 8px 10px;
    height: 40px;
  }

  .smBtn {
    height: 32px;
    padding: 4px 12px !important;
    display: flex;
    color: $metal_blue;
    align-items: center;
    gap: 5px;
  }

  .infoActionBox {
    > div {
      cursor: pointer;
    }

    h3 {
      height: 100%;
      box-shadow: 0px 8px 16px rgba(143, 149, 178, 0.15);
      border-radius: 16px;
      flex-direction: column;
      padding: 20px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;

      &.lightGreen {
        background: $paperbackgroundGreen;
      }

      &.lightBlue {
        background: $paperbackgroundBlue;
      }

      b {
        font-size: 30px;
      }

      > div {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 10px;

        > span {
          display: block;
          margin-left: auto;
          opacity: 0.7;
          font-weight: bold;
        }
      }

      .statusCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        color: $darkHeader;
        font-weight: 500;
        font-size: 22px;
        cursor: pointer;
        flex-wrap: wrap;

        svg {
          width: 16px;
          height: 16px;
          color: $metal_blue;
        }
      }
    }
  }

  .companyDetailsEditDeleteRow {
    .ant-btn {
      display: inline-flex;
      gap: 6px;
      align-items: center;
      font-weight: 500;

      svg,
      path {
        color: var(--accent-color);
        fill: var(--accent-color);
      }
    }

    .deleteBtn,
    .deleteBtn:hover {
      color: $dangerColor;
      border-color: $dangerColor;
    }

    .editBtn,
    .editBtn:hover {
      color: $inputFocus;
      border-color: $inputFocus;
    }

    .lightBtn,
    .lightBtn:hover {
      font-size: 14px;
      padding: 8px;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.boxActions span {
  cursor: pointer;
}

.ant-breadcrumb ol li:first-child a svg {
  width: 100%;
  height: 100%;
}

.welcomeHeading {
  width: 100%;
  font-weight: 600 !important;
  font-size: 32px !important;
  color: #16aff4 !important;
  line-height: 32px !important;
}

.arrowBtnInside svg {
  height: 10px !important;
  margin-left: 6px;
}

.totalPadx {
  background: #e9ffd8;
  font-weight: 600;
  font-size: 24px;
  word-spacing: 1px;

  padding: 14px;
  border-radius: 6px;
}

.customFieldsGrid {
  > .ant-col > div {
    padding: 20px !important;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(143, 149, 178, 0.15);
    border-radius: 16px;
    width: 100%;
    position: relative;
  }

  .valueRead {
    text-align: right;
    color: $buttonColor;
    font-weight: 500;
    align-self: flex-end;
  }

  label > div:first-child {
    font-weight: 500;
    margin: 30px 0 10px;
    color: $metal_blue;
  }

  .ant-col label:first-child > div:first-child {
    margin: 10px 0 10px;
  }

  .ant-radio-group {
    width: fit-content;
  }

  .ant-radio-button-wrapper {
    flex: auto;
    padding: 5px 13px !important;
  }

  .ant-radio-button-wrapper span {
    white-space: nowrap;
  }

  .boxActions {
    display: flex;
    flex-direction: column;
    gap: 20px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .values {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    > div {
      padding: 4px;
      border: 1px solid $inputFocus;

      svg {
        color: $dangerColor;
        cursor: pointer;
      }
    }
  }

  .inputLg {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    min-width: 300px;
    font-family: "Montserrat", system-ui, sans-serif;
  }

  .inputSm {
    margin-top: 10px;
    padding: 5px;
    font-size: 14px;
    min-width: 200px;
  }

  .gridBox {
    margin-bottom: 40px;
  }
}

.diviStyle {
  .data {
    max-height: 220px;
    overflow: auto;
  }

  .data ul {
    margin: 0 !important;
    margin: 0 !important;
    padding-left: 14px;
    list-style-type: circle;
    font-size: 16px;
  }
}

.disabledSec {
  filter: grayscale(1);
  opacity: 0.5;
  pointer-events: none;
}

.addMemberTable.stdForm {
  .checkWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    flex-direction: column;
  }

  .rowCheckbox {
    .ant-checkbox-checked .ant-checkbox-inner {
      background: #16aff4 !important;
    }
  }

  .ant-checkbox-inner {
    border-radius: 0 !important;
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
    position: relative;

    // top: 2px;
    &::after {
      left: 5px;
    }
  }
}

.ant-tag {
  font-size: 14px;
  background: none !important;
  padding: 4px 12px;
  border-radius: 8px;
  border-color: var(--color);
  color: var(--color);

  &.green,
  &.ant-tag-green {
    border-color: $inputFocus !important;
    color: $inputFocus !important;
  }

  &.red,
  &.ant-tag-red {
    border-color: $dangerColor !important;
    color: $dangerColor !important;
  }

  &.blue,
  &.ant-tag-blue {
    border-color: $buttonColor !important;
    color: $buttonColor !important;
  }
}

.defautlBtnPad {
  padding: 10px 16px;
  height: auto;
}

.blueUnder {
  text-decoration: underline;
  color: $buttonColor;
  font-weight: 500;
  cursor: pointer;
}

.greenUnder {
  text-decoration: underline;
  color: $inputFocus;
  font-weight: 500;
  cursor: pointer;
}

.borderedBox {
  border: 1px solid $metal_blue;
  padding: 16px;
  border-radius: 14px;
}

.dropdownOption {
  min-height: 42px !important;
  align-items: center;
}

.commonH,
.tempLivingTitle,
.clientListHeading {
  font-weight: 600 !important;
  font-size: 24px !important;
  margin: 0 !important;
}

.tempLivingTitle,
.clientListHeading {
  padding-top: 12px;
  padding-bottom: 20px !important;
}

.commonHH {
  font-weight: 600 !important;
  font-size: 30px !important;
  margin: 0 !important;
  padding: 0;
}

.textMedium {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

h2.commonH {
  font-size: 22px !important;
}

h3.commonH {
  font-size: 20px !important;
  font-weight: 600;
}

.flexCenter {
  justify-content: center;
}

h4.commonH {
  font-size: 18px !important;
  color: #4f535f;
  font-weight: 600;
}

h5.commonH {
  font-size: 16px !important;
  font-weight: 600;
}

.filterGrid {
  display: grid;
  gap: 10px;
  grid-template-columns: 3fr 1fr;
  align-items: flex-start;

  &.flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    align-items: center;

    .ant-select {
      width: 200px;
    }
  }

  label {
    > div {
      margin-bottom: 16px;
      color: #3b414f;
    }
  }

  .ant-select {
    width: 100%;
    height: 36px;

    .ant-select-selector {
      height: 36px;

      > span {
        height: 34px;
        padding-top: 2px;
      }
    }
  }

  input {
    height: 36px;
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 18px;

    button {
      height: 36px;
      padding: 0 24px;
    }
  }

  .ant-select-selection-placeholder {
    padding-top: 1px !important;
  }
}

.rc-virtual-list-holder {
  overflow-y: auto !important;
}

.rc-virtual-list-holder::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 10px;
}

.rc-virtual-list-holder::-webkit-scrollbar-thumb {
  background: #bbb;
}

.rc-virtual-list-holder::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.rc-virtual-list {
  .rc-virtual-list-scrollbar {
    display: none !important;
  }
}

.ligthDropdown {
  display: flex;
  align-items: center;
  height: 36px;
  border-color: #626c81 !important;
}

.ant-dropdown-menu-title-content {
  padding: 6px 2px;
}

.commonLabel {
  color: $metal_blue;
  font-weight: 500;
  margin-bottom: 6px;
  font-size: 15px;
  text-transform: capitalize;
  width: 100%;
}

.commonLabelSpacing {
  margin-right: 30px;
  padding-right: 30px;
  font-weight: 700;
  font-size: 15px;
}

.spacing {
  margin-right: 20px;
}

.commonData {
  font-weight: 600;
  font-size: 17px;
}

.stdMTop {
  margin-top: 40px !important;
}

.submitSpaceEnd {
  display: flex;
  justify-content: flex-end;

  align-items: flex-end;
  background-color: #16aff4;
  width: 150px;
  height: 40px;
  align-items: center;
  padding-right: 50px;
}

.stdSTop {
  margin-top: 20px !important;
}

.stdM0 {
  margin-top: 0 !important;
}

.ant-dropdown-menu-item-icon {
  max-height: 30px;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.reportSearch {
  > div:first-child {
    min-width: 30%;
  }

  .ant-input-affix-wrapper {
    width: 100% !important;
    min-height: 36px;
  }
}

.resetToggle {
  cursor: pointer;

  svg {
    height: 36px;
  }
}

.vendorListingActions svg {
  height: 18px;
  margin-top: 4px;
}

.mainShadow {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(143, 149, 178, 0.15);
  border-radius: 16px;
  width: 100%;
  position: relative;
}

.tempListingWrapper .ant-breadcrumb li:first-child {
  position: relative;
  top: 2px;
}

.uploadDrag {
  cursor: pointer;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  gap: 16px;
  text-align: center;
  border: 1px dashed $buttonColor;
  border-radius: 16px;

  &.drag {
    border-color: #333;
    opacity: 0.5;
    filter: grayscale(1);
  }

  div {
    font-weight: 400;
    font-size: 15px;
  }

  svg {
    width: 32px;
    height: 32px;
    color: #333;
  }
}

button,
input,
option,
textarea,
select {
  font-family: "montserrat", sans-serif !important;
}

.mainShadow.lg {
  padding: 30px;
}

.listTable {
  overflow: auto;

  .ant-pagination {
    display: none !important;
  }

  .ant-table-tbody {
    > tr:nth-child(odd) td {
      background: #f9f9f9 !important;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    position: relative;
    top: 2px;
  }
}

.rowTableHead {
  .ant-col {
    border-bottom: 1px solid #ddd;
    padding: 12px;
    font-weight: 500;
  }
}

.rowTableBody {
  .ant-col {
    padding: 12px;
    color: $metal_blue;
    line-height: 1.4;
  }

  .commonH {
    color: $darkHeader;
  }

  ul {
    padding-left: 16px;
    font-size: 16px;
    margin-top: 0;
    font-weight: 600;
  }
}

.mainListing {
  margin-top: 40px;
}

.spaceCenter {
  justify-content: center;
}

.customDrawerStyle {
  max-width: 350px;
  box-shadow: 0 0 10px 1px #3333;
  margin-left: auto;

  .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    margin: 0;
  }

  .ant-drawer-title {
    font-size: 20px;
  }

  .ant-drawer-header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .ant-drawer-body {
    padding-top: 0;

    > span {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }
  }

  .ant-descriptions-title {
    font-weight: 400;
  }

  .ant-descriptions-header {
    margin-bottom: 16px !important;
  }

  .ant-card-body {
    display: flex;
    padding: 5px 10px;
    box-shadow: 0px 8px 16px 0px #8f95b226;

    & .ant-picker {
      margin: auto;
    }

    .ant-picker:first-of-type::after {
      content: "";
      display: block;
      width: 10px;
      height: 1px;
      background: #ccc;
      position: absolute;
      right: -4px;
      top: calc(50% - 1px);
    }
  }

  .singleDate {
    width: fit-content;
    border: none !important;
  }

  .singleDate .ant-picker:first-of-type::after {
    display: none;
  }

  .dashBoardFilterDate {
    border: none;
  }

  .saveNameWrap {
    margin-top: 30px;
    margin-bottom: 20px;

    & > span {
      padding: 10px 0;
    }

    svg {
      width: 36px;
      height: 20px;
    }
  }

  .pociyMb0 .ant-descriptions-header {
    margin-bottom: 0 !important;
  }

  .ant-checkbox-checked:after {
    display: none !important;
  }
}

.flexEnd {
  justify-content: flex-end;
}

.textright {
  text-align: right;
}

.donutList {
  border: none;
  width: 100%;
  justify-content: center;

  .ant-list-items {
    > div {
      padding: 6px 0;
    }
  }
}

.donutRadioRow {
  margin-bottom: 20px;

  .ant-radio-button-wrapper {
    min-width: 50px;
  }
}

.brokenImage {
  display: flex;
  align-items: center;
  color: #888;
  gap: 8px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.ant-radio-button-checked {
  background-color: $selectedItemColor;
  border-color: $selectedItemColor;
  color: $color-white !important;
  border-radius: 5%;
}

.ant-radio-button-wrapper {
  border: none !important;
  padding: 5px 7px !important;
  height: auto;
  overflow: hidden;
  flex: 1;
  border-radius: 6px !important;
  text-align: center;

  &-checkded {
    box-shadow: 1px 6px 18px 2px rgba(22, 175, 244, 0.2);
  }
}

.ant-radio-button-wrapper:before {
  display: none;
}

.ant-radio-group {
  box-shadow: 1px 8px 16px rgba(143, 149, 178, 0.15);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
}

.ant-checkbox-inner {
  outline: none !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  transform: translate(0, -5px);
}

.ant-checkbox + span {
  padding-inline-start: 12px;
}

.dashboardFilter {
  .filterDefaultResetBtnGroup {
    margin-top: 20px;
  }

  .setAsDefaultBtn {
    border-color: $selectedItemColor;
    color: $selectedItemColor;
  }

  .ant-radio-button-wrapper-checked {
    color: $color-white !important;
  }

  .descriptionLabel {
    margin-top: 26px;
    color: $descriptionLabel !important;
    @include descriptionLabel;
  }

  .statusFilter {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }
}

.resetBtn {
  color: #df4f63;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.center {
  text-align: center;
}

.largeTag {
  padding: 30px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  font-size: 18px;
  gap: 14px;
  color: $darkHeader;
  font-weight: 500;

  &:hover {
    color: $darkHeader !important;
  }

  svg {
    width: 34px;
    height: 34px;
  }

  &.colorBlue,
  &.colorBlue:hover {
    border-color: $buttonColor;
    background: $paperbackgroundBlue;

    svg {
      color: $buttonColor;

      path {
        fill: $buttonColor;
      }
    }
  }

  &.xs {
    padding: 20px 20px;
    font-size: 16px;
    gap: 5px;

    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.colorGreen,
  &.colorGreen:hover {
    border-color: $inputFocus;
    background: $paperbackgroundGreen;

    svg {
      color: $inputFocus;

      path {
        fill: $inputFocus;
      }
    }
  }

  &.colorRed,
  &.colorRed:hover {
    border-color: $dangerColor;
    background: rgba($dangerColor, 0.1);

    svg {
      color: $dangerColor;

      path {
        fill: $dangerColor;
      }
    }
  }
}

.spaceVertical {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.stdForm {
  .mainShadow.lg {
    padding: 32px 30px 40px 30px;
  }

  textarea {
    background: #fafafa;
  }

  .noShadow {
    box-shadow: none !important;
    padding: 0 !important;
  }

  .inputTextarea textarea {
    height: 46px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    text-transform: capitalize;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .ant-select-clear {
    width: 18px;
    height: 18px;
    margin-top: -7px;
    background: #fafafa;
    font-size: 14px;
  }

  .ant-input-clear-icon svg {
    width: 14px;
    height: 14px;
    position: relative;
    top: 1px;
  }

  .stdFormTitle {
    display: flex;
    gap: 10px;

    svg {
      width: 42px;
      height: 42px;
      filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
      position: relative;
      top: -8px;
    }
  }

  .ant-form-item-row {
    flex-direction: column;
    width: 100%;

    .ant-col {
      width: 100%;
      text-align: start;
      flex: auto;
    }

    label {
      color: #3b414f;
      font-size: 16px;
      margin-bottom: 10px;

      &::before {
        display: none !important;
      }

      &::after {
        content: "";
      }

      &.ant-form-item-required::after {
        content: "*";
      }
    }

    .ant-select-selector,
    .ant-input-affix-wrapper,
    .ant-form-item-control-input-content > input {
      height: 48px !important;
      align-items: center;
      background: #fafafa !important;
    }

    .ant-input-textarea-affix-wrapper {
      height: auto !important;

      .ant-input {
        font-size: 16px;
        padding: 10px;
      }
    }

    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }

    input {
      font-size: 16px;
      background: #fafafa;
    }

    .ant-input-prefix {
      font-weight: 600;
      color: $darkHeader;
      font-size: 16px;
      margin-right: 8px;
    }

    .ant-input-affix-wrapper-disabled,
    .ant-select-disabled {
      background: #ededed !important;
      border-radius: 8px;

      input {
        color: #000;
        background: #ededed !important;
      }
    }
  }

  label.labelRow {
    font-size: 20px;
    font-weight: 600;
    color: #4f535f;
    margin-bottom: -16px;
  }

  .ant-input,
  .ant-input,
  .ant-input {
    border-color: #7e84a3;
    box-shadow: none;
  }

  .ant-select-selection-placeholder {
    font-size: 16px;
    color: #616c81;
  }

  .ant-form-item-control-input-content:focus > input {
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  }

  .ant-checkbox + span {
    font-size: 16px;
  }

  .ant-checkbox-checked:after {
    display: none;
  }

  .ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: #fff;
  }

  .ant-select-arrow svg {
    height: 16px;
    width: 16px;
  }

  .ant-checkbox-disabled + span {
    color: #3b414f;
  }

  .ant-checkbox-inner {
    outline: none !important;
    width: 25px !important;
    height: 25px !important;
    border-radius: 50% !important;
    transform: translate(0, -2px);

    &::after {
      left: 7px;
    }
  }

  .nextBtn,
  .backBtn,
  .cancelBtn {
    padding: 8px 28px;
    background: #03a9f4;
    border-color: #03a9f4;
    border-width: 2px;
    height: auto;
    font-weight: 500;
    min-width: 140px;
    color: #fff;
    font-size: 15px;
  }

  .nextBtn.light {
    color: #03a9f4;
    background: #fff;
  }

  .cancelButton {
    padding: 8px 28px;
    background: #03a9f4;
    border-color: #03a9f4;
    border-width: 2px;
    height: auto;
    font-weight: 500;
    min-width: 140px;
    color: #fff;
    font-size: 15px;

    &.fill {
      background: $dangerColor !important;
      color: #fff !important;
    }
  }

  .nextBtn:disabled {
    filter: grayscale(1);
  }

  .backBtn {
    border: 2px solid $buttonColor;
    color: $buttonColor;
    background: none;
  }

  .cancelBtn {
    border: 2px solid $dangerColor;
    color: $dangerColor;
    background: none;

    &.fill {
      background: $dangerColor !important;
      color: #fff !important;

      &:hover {
        border-color: $dangerColor;
      }
    }
  }

  .ant-select-single {
    height: auto;
  }

  .delBtn,
  .delBtn:active,
  .delBtn:hover {
    border: 2px solid $dangerColor !important;
    background: $dangerColor !important;
    color: #fff !important;
  }

  .cancelButton {
    border: 2px solid $dangerColor;
    color: $dangerColor;
    background: none;
    margin-left: auto;

    &.fill {
      background: $dangerColor !important;
      color: #fff !important;
    }
  }

  h4.commonH {
    margin-top: 20px !important;
  }

  .mainShadow > h4.commonH {
    margin-top: 0px !important;
    margin-bottom: 24px !important;
  }

  .checkList {
    .ant-checkbox-inner {
      border-radius: 0 !important;

      &::after {
        left: 11px;
        width: 14px;
        height: 14px;
        background: $buttonColor;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background: $buttonColor !important;
      border-color: $buttonColor !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
      left: 6px;
      top: 10px;
      width: 7px;
      height: 14px;
      background: none !important;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
    }

    .ant-checkbox-group {
      margin-top: 40px;
      display: flex;
      gap: 30px 40px;
      flex-wrap: wrap;

      .ant-checkbox {
        display: none;

        + span {
          transition: all 0.25s;
          padding: 8px 14px;
          cursor: pointer;
          border: 1px solid #fff0;

          &:hover {
            border: 1px solid $inputFocus;
          }
        }

        &.ant-checkbox-checked + span {
          border: 1px solid $inputFocus;
          background: $inputFocus;
          color: #fff;
        }
      }
    }
  }

  .totalRow {
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $inputFocus;
    border-radius: 0 0 0 20px;
    border-top: 0;
    border-right: 0;
    font-size: 18px;
    gap: 6px;
    font-weight: 600;
  }

  .stdWrap {
    .ant-col {
      display: flex;
      flex-direction: column;
    }
  }

  .buttonOver {
    padding-top: 3px;

    button {
      padding: 10px 20px;
      width: fit-content;
      height: auto;
      border-color: $buttonColor;
      color: $buttonColor;
      font-weight: 500;
    }
  }

  .ant-form-item {
    margin: 0 !important;
  }

  .fallAfter {
    margin-right: 33%;
  }

  .fallAfterTwo {
    margin-right: 34%;
  }

  .fallAfterTwo5 {
    margin-right: calc(34% + 180px);
  }

  .fallAfterThree {
    margin-right: 64%;
  }

  .fallAfterFour {
    margin-right: 80%;
  }

  .fallAfterFour5 {
    margin-right: calc(80% + 180px);
  }

  .note {
    margin-top: 5px;
    line-height: 1.3;
    color: #f19f06;
    width: auto;
  }

  .ant-alert {
    align-items: flex-start;
  }

  .ant-alert-content {
    font-weight: 500;
    white-space: break-spaces;
  }

  .ant-alert-warning {
    background-color: #ffe7ba;

    .anticon svg {
      color: #f19f06 !important;
      position: relative;
      top: 3px;
    }
  }

  .ant-radio {
    zoom: 1.1;
  }

  .ant-radio-checked {
    .ant-radio-inner::after {
      background: $inputFocus;
      transform: scale(0.5);
    }

    .ant-radio-inner {
      border-color: $inputFocus;
      background: none;
    }
  }

  &::after {
    border-color: #ddd;
  }

  .ant-radio-inner {
    border-color: #aaa;
    background: none;
  }

  .ant-radio-group {
    box-shadow: none;
  }

  .ant-radio-wrapper {
    margin-bottom: 0 !important;
  }

  input {
    font-family: "Montserrat", system-ui, sans-serif;
  }

  .ant-picker {
    background: #fafafa;
    width: 100%;
    height: 48px;
    border-color: #626c81;

    input {
      background: #fafafa;
      font-weight: 400;
      font-family: "Montserrat";
    }

    font-weight: 400;
  }
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 250px;
  display: inline-block;
  white-space: nowrap;
}

.spaceBetween {
  justify-content: space-between;
}

.disabledX {
  cursor: not-allowed !important;
  pointer-events: none;
  opacity: 0.5;
  filter: grayscale(1);
}

.disabledBtn {
  cursor: not-allowed !important;
  pointer-events: none;
  opacity: 0.5;
}

#chartjs-tooltip {
  background: #111;
  color: #fff;
  border-radius: 6px;
  padding: 6px;
  z-index: 4;
  text-align: left;

  th {
    font-weight: 600;
    font-size: 14px;
  }
}

.popOverContent {
  h4 {
    margin: 0;
  }

  &.edit {
    min-width: 250px;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;

      h4 {
        font-size: 17px;
      }

      .edit {
        color: $inputFocus;
        font-weight: 500;
        font-size: 15px;
        cursor: pointer;
      }

      .data {
        max-width: 220px;
        overflow: auto;
      }
    }

    .ant-tag {
      &.green,
      &.ant-tag-green {
        background-color: rgba($inputFocus, 0.1) !important;
      }

      &.red,
      &.ant-tag-red {
        background-color: rgba($dangerColor, 0.1) !important;
      }

      &.blue,
      &.ant-tag-blue {
        background-color: rgba($buttonColor, 0.1) !important;
      }
    }

    .ant-tag {
      display: block;
      margin-bottom: 10px;
      width: fit-content;
    }
  }
}

.ant-popover-placement-topLeft,
.ant-popover-placement-rightBottom {
  .anticon svg {
    color: #333;
  }

  .ant-popconfirm-message-title {
    font-weight: 600;
  }

  .ant-popconfirm-buttons {
    padding-top: 6px;
  }

  .ant-btn-sm {
    height: 28px;
    padding: 0px 10px;

    span {
      font-weight: 500 !important;
    }
  }

  .ant-btn-primary {
    background: $buttonColor;
  }

  .ant-btn-default {
    border-color: $dangerColor;
    color: $dangerColor;
  }

  ul {
    padding-left: 16px;

    li {
      font-size: 16px;
      list-style-type: circle;
      margin-top: 5px;
    }
  }
}

.ant-picker-calendar {
  .ant-select .ant-select-arrow {
    margin-top: -3px !important;
  }
}

.ant-btn-default:hover {
  background-color: auto !important;
}

.radioBoolean {
  width: fit-content;

  .ant-radio-button-wrapper {
    white-space: nowrap;
    min-width: 120px;
  }

  .ant-radio-button-wrapper:first-child .ant-radio-button-checked {
    background-color: $dangerColor;
  }
}

.padGray {
  padding: 14px;
  background: #f9f9f9;
  border-radius: 8px;
}

.modalTitle {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-family: Montserrat;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;
  /* 120% */
}

.progressList > div > div {
  max-width: calc(100% - 125px);
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.progressList {
  > div {
    padding: 16px;
    border: 1px dashed #d9d9d9;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
    color: $buttonColor;

    img {
      max-width: 100px;
      max-height: 50px;
      height: auto;
      width: auto;
    }

    > div:last-child {
      flex: 1;
    }

    position: relative;

    .deleteProgress {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      cursor: pointer;
      color: $darkHeader;
      z-index: 2;

      &:hover {
        color: $dangerColor;
      }
    }

    .progressBar {
      width: 100%;
      height: 1px;
      background: #ddd;
      margin-top: 14px;

      span {
        display: block;
        height: 1px;
        width: 0;
        background: $inputFocus;
      }
    }
  }
}

.fileShareTable {
  .action {
    cursor: pointer;
  }

  .view.action {
    margin-right: 8px !important;
    top: 3px;
    position: relative;
  }

  .delete.action {
    svg {
      height: 20px;
    }
  }
}

.modalSubTitle {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Montserrat;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500;
  line-height: normal;
}

.adminToolBarBtn {
  width: fit-content;

  &:disabled {
    color: #fff;
    filter: grayscale(1);
  }

  &.fillWhite {
    svg path {
      fill: #fff !important;
    }
  }

  &.btnRed {
    background: #df4f63 !important;
    color: #fff !important;
    border-color: #df4f63 !important;
  }
}

.rowNWrap {
  flex-wrap: nowrap;
}

.ant-select-item-option {
  padding: 10px 12px !important;
}

.sortBySelect {
  .ant-select-selector {
    height: 34px !important;
    padding-left: 42px !important;
  }

  span.ant-select-arrow {
    right: unset;
    left: 4px !important;
    color: #555 !important;
  }

  .ant-select-selection-placeholder {
    color: #555 !important;
  }
}

.stepGreen {
  .ant-steps-item-finish {
    .ant-steps-finish-icon {
      color: $inputFocus;
    }

    .ant-steps-item-icon {
      border-color: $inputFocus;
      background: none;
    }

    .ant-steps-item-title {
      color: $inputFocus !important;
    }
  }

  .ant-progress-circle > circle:nth-child(2) {
    stroke: $inputFocus !important;
  }

  .ant-steps-item-active {
    .ant-steps-item-icon {
      border-color: $inputFocus !important;
      background: $inputFocus !important;
    }

    .ant-steps-item-title {
      color: $inputFocus !important;
    }
  }

  .ant-steps-item-title {
    font-size: 14px !important;
  }

  .ant-steps-item-title::after {
    background: #ddd !important;
  }
}

.centerModal {
  .ant-modal-body {
    text-align: center;

    > img {
      max-width: 260px;
    }

    h4 {
      font-size: 20px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 0;
    }

    p {
      color: $darkHeader;
      margin-top: 4px;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0;
    padding: 20px 0 0;

    button {
      padding: 8px 28px;
      background: $buttonColor;
      border-color: $buttonColor;
      height: auto;
      font-weight: 500;
      min-width: 140px;
      color: #fff;
      font-size: 15px;
      box-shadow: 0 0 10px 1px #3331;
    }

    > button:first-child {
      background: none;
      border: 2px solid $buttonColor;
      color: $buttonColor;
      box-shadow: none;
    }
  }
}

.modalRedBtn,
.modalRedBtn:hover {
  border: 1px solid $dangerColor;
  color: $dangerColor;
}

.modalBlueBtn,
.modalBlueBtn:hover {
  border: 1px solid $buttonColor !important;
  color: #fff !important;
  background: $buttonColor !important;

  &:disabled {
    filter: grayscale(1);
    background: $buttonColor;
    color: #fff !important;
  }
}

.ant-tooltip .ant-tooltip-inner {
  color: #111 !important;
  background: #fff !important;

  h4 {
    margin: 0;
  }

  p {
    margin: 0;
    margin-top: 5px;
  }
}

.helpBudgetClass {
  .ant-radio-wrapper-disabled,
  .last span {
    color: red;
  }
}

br,
hr {
  user-select: none;
}

.br5 {
  height: 5px;
}

.br10 {
  height: 10px;
}

.br20 {
  height: 20px;
}

.br30 {
  height: 30px;
}

.br40 {
  height: 40px;
}

.br50 {
  height: 50px;
}

.nowrapTable {
  .ant-table-thead th.ant-table-cell {
    white-space: nowrap;
  }
}

.newTagStyle {
  --color: #556fff;
  padding: 0px 6px;
  font-size: 10px;
  background: var(--color) !important;
  color: #fff !important;
  font-weight: 600;
  margin: 0;
  margin-left: 3px;
  top: -3px;
  border-radius: 4px;
}

.transfereeAuthIcon svg {
  width: 40px;
  height: 40px;
}

.cellOverflowAuto {
  .ant-table-cell > div {
    overflow: auto;
    max-height: 100px;
  }
}

.btnGreen {
  background: $inputFocus !important;
  color: #fff !important;
  border-color: $inputFocus !important;
}

.btnRed {
  background: $dangerColor !important;
  color: #fff !important;
  border-color: $dangerColor !important;

  &:disabled {
    filter: grayscale(1);
  }

  &.light {
    background: #fff !important;
    color: $dangerColor !important;
  }
}

.btnBlue {
  background: $buttonColor !important;
  color: #fff !important;
  border-color: $buttonColor !important;

  &.light {
    background: #fff !important;
    color: $buttonColor !important;
  }

  &:disabled {
    opacity: 0.5;
    filter: grayscale(1);
  }
}

.video-react .video-react-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.video-react {
  font-family: sans-serif;
}

.video-react .video-react-big-play-button:before {
  top: 10px;
  position: relative;
}

.stdBtn {
  background: $buttonColor;
  outline: none;
  border: 1px solid $buttonColor;
  padding: 10px 28px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;

  &:disabled {
    filter: grayscale(1);
    opacity: 0.7;
    pointer-events: none;
  }

  &.light {
    background: none;
    color: $buttonColor;

    &.red {
      color: $dangerColor;
      border-color: $dangerColor;
    }
  }
}

.roadTimeline {
  --accent-color: #f19f06;

  .roadTimelineIcon {
    height: 72px;
    width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: -72px;
    border: 1px solid var(--accent-color);
    background-color: #fff;

    svg {
      width: 36px;
      height: 36px;
      z-index: 2;
      position: relative;

      &:not([fill="none"]) {
        fill: var(--accent-color);
      }

      path:not([fill="none"]):not([fclip-rule]):not([fill]) {
        fill: var(--accent-color);
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--accent-color);
      opacity: 0.1;
    }
  }

  .ant-timeline-item {
    padding-bottom: 50px;
  }

  .ant-card-body {
    padding: 26px 26px 26px 34px !important;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      border-radius: 99px;
      height: 100%;
      background: var(--accent-color);
    }
  }

  .ant-card {
    border-radius: 6px;
    background: #fff;
    border: none;
    box-shadow: 1px 1px 15px 0px rgba(94, 134, 152, 0.23);
  }

  .action {
    position: absolute;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .eventColumn {
    position: static;
  }

  .eventEdit {
    top: 14px;
    right: 14px;
  }

  .eventPlus {
    bottom: 14px;
    right: 14px;
  }

  p.eventDate {
    font-size: 16px;
    line-height: 1.3;
    color: #111;
    font-weight: 500;
  }

  h3.eventName {
    margin: 5px 0 !important;
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .hr {
    width: 50%;
    margin: 0 0 10px 0;
    height: 1px;
    background: #ddd;
  }

  .eventDescription {
    font-size: 15px;
    font-weight: 500;
  }
}

.canNotDelete {
  .delete {
    display: none;
  }
}

.canNotView {
  .view {
    display: none;
  }
}

.doubleDonutheading {
  font-weight: 400 !important;
}

.doubleDonutGraph {
  .doubleDonutTableCol {
    overflow: auto;
  }

  table {
    width: 100%;

    td {
      font-size: 15px;
      padding-bottom: 30px;
      white-space: nowrap;
    }

    td:last-child {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .donutLabelDot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 40px;
  }
}

.disabledInput {
  border-radius: 12px;
  border: 1px solid #7e84a3;
  background: #ccc !important;
}

.saveBtn {
  border-radius: 6px;
  border: 2px solid #16aff4;
  background-color: #16aff4;
  height: 40px;
  color: white;
  box-shadow: 1px 1px 15px 0px rgba(22, 175, 244, 0.23);
}

// lower screens

@media (max-width: 1280px) {
  .donutheading {
    margin-bottom: 60px !important;
  }

  .donutList {
    .ant-list-items {
      > div {
        display: flex;
        flex-direction: column;

        > div {
          width: 100%;
          max-width: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .dashBoardCommonStyle {
    .dashBoardSlider {
      position: fixed;
      top: 0;
      right: 0;
      width: 90% !important;
      max-width: 290px !important;
      z-index: 1000;
      background: #fff;
      transform: translateX(120%);
      transition: all 0.3s ease-in-out;
    }

    .dashBoardSlider.open {
      transform: translateX(0);
    }

    .relologoNavBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .closeSlider {
      color: #111;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .ant-space {
    flex-wrap: wrap;
  }

  .dashboardSinglePad {
    margin-right: auto;
    padding-left: 30px;
  }

  .dashBoardLayout .dashboardHeaderRight .dashboardRightInner {
    gap: 14px;
  }

  .dashBoardLayout .navigationLogo {
    width: 120px !important;
  }

  .mainShadow {
    padding: 18px !important;
  }

  .mainShadow.lg {
    padding: 22px;
  }

  .stdForm .totalRow {
    padding: 12px 24px;
    font-size: 18px;

    min-width: none;
  }

  .stdForm .mobileTop {
    padding-top: 75px !important;
  }

  .ant-steps.overflowMobile {
    flex-direction: row !important;
    overflow: auto;

    .ant-steps-item-tail:after {
      display: none !important;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  .stdForm .ant-form-item-row label.ant-form-item-required::after {
    content: "*";
    display: block !important;
  }

  .stdForm .ant-checkbox .ant-form-item-required::after {
    content: "*";
    display: block !important;
  }

  .roadTimeline {
    .roadTimelineIcon {
      height: 40px;
      width: 40px;
      position: relative;
      left: -24px;
      top: -6px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .ant-card-body {
      padding: 22px 22px 22px 22px !important;
      position: relative;
    }

    .ant-timeline-item-tail {
      inset-inline-start: -20px;
    }

    .ant-timeline {
      padding-left: 10px !important;
    }
  }

  .doubleDonutGraph {
    .donutLabelDot {
      margin: 0 10px;
    }
  }

  .filterGrid {
    grid-template-columns: 1fr;
  }

  .ant-table-cell {
    min-width: 100px;
  }

  .adminDashBoardLayout {
    padding: 20px 16px;
  }

  .stdForm {
    .mainShadow.lg {
      padding: 22px 20px 20px 20px;
    }
  }

  .stdForm .ant-form-item-row label {
    font-size: 16px;
    margin-bottom: 0px;
    height: auto;
  }

  .mainShadow.lg.stdMTop.stdWrap.showTotalWrap {
    padding-top: 74px !important;
  }
}

@media screen and (max-width: 576px) {
  .stdForm {
    .noLabel {
      .ant-form-item-label {
        display: none !important;
      }
    }
  }

  .welcomeHeading {
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .welcomeBodyText {
    display: block;
  }

  .dashBoardCommonStyle .dashboardBarGraph {
    min-width: unset;

    .barChartStyle {
      min-height: unset !important;
      min-width: unset !important;
      height: unset !important;
    }
  }

  .barChartStyle {
    .canvasWrapper {
      width: 130vw;
    }
  }

  .dashBoardCommonStyle .dashboardBarGraph .barChartLegends {
    gap: 12px;
  }
}
