.mainLayout {
  height: 100vh;
}

.mainContentLayout {
  height: calc(100vh - 80px);
  overflow: auto;
}

.envLabel {
  z-index: 99999999999;
  font-size: 14px;
  padding: 8px 12px;
  background: #f9f91b;
  font-weight: 600;
  position: fixed;
  bottom: 20px;
  left: 20px;
  border-radius: 6px;
  box-shadow: 1px 1px 10px 1px #3333;
  font-family: monospace;
  letter-spacing: 2px;
}
