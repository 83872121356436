.loaderTemp {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: rgb(240, 240, 240, 0.5);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  flex-direction: column;

  svg,
  > div {
    width: 400px !important;
    // height: calc(400px * 9 / 16) !important;
  }

  @media (max-width: 600px) {
    svg,
    > div {
      width: 180px !important;
      // height: calc(180px * 9 / 16) !important;
    }
  }
}

.linear-activity {
  overflow: hidden;
  width: 50%;
  height: 4px;
  background-color: #b3e5fc;
  margin: 20px auto;
}

.loadData {
  position: relative;
  top: -20px;
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
}

.progressText {
  position: relative;
  top: -20px;
  font-weight: 500;
  text-align: center;
}

.determinate {
  position: relative;
  max-width: 100%;
  height: 100%;
  -webkit-transition: width 500ms ease-out 1s;
  -moz-transition: width 500ms ease-out 1s;
  -o-transition: width 500ms ease-out 1s;
  transition: width 500ms ease-out 1s;
  background-color: #03a9f4;
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #03a9f4;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #4fc3f7;
  animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}
