/* Global styles */

/* table column styles */
.normalColumn {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #626c81;
}

.linkColumn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #81c54b;
  cursor: pointer;
}

.selectDropDown {
  display: flex;
  flex-direction: column;
  min-height: 32px !important;
  margin-top: 8px;
}

.titleClass {
  font-size: 16px;
  color: #626c81;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
}

.highlightedColumn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #202020;
}

.greenBreadCrumb {
  color: #81c54b;

  a {
    color: #81c54b;
  }
}

.loaders {
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.addConfigBtn {
  display: flex;
  width: auto;
  height: 42px;
  border-radius: 6px;
  background: #16aff4 !important;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  color: white;
  flex-shrink: 0;
}

.actionButtons {
  color: #81c54b;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  border: none;
  background: transparent;
  box-shadow: none;
  font-weight: 500;
  line-height: 22px;
}

.newRow {
  margin-top: -30px;
  padding: 0;
}

.deleteBtn {
  border-radius: 6px;
  background: white;
  color: #d84049;
  font-weight: 500;
  border: 1px solid #d84049;
}
.deleteBtn:hover {
  background: white !important;
  color: #d84049 !important;
}
.deleteBtn:focus {
  background: white !important;
  color: #d84049 !important;
}

.mtAuto {
  margin-top: auto;
}

.disabledBtn {
  opacity: 50%;
  color: white;
}

.radioBtn {
  display: flex;
  flex-direction: row;
}
